import './main.scss';
import Form from '../../components/Form/Form';
import Popup from '../../components/other/Popup';
import Api from '../../requests/Api';

(function () {
    const $footerYear = document.querySelector('.js-footer-year');
    const forms = document.querySelectorAll('.js-form');
    const popups = document.querySelectorAll('.js-popup');
    const activatedButtons = document.querySelectorAll('.js-activated-button');
    const $Request = new Api();
    let formsCollection = {};
    let popupsCollection = {};

    forms.forEach(($form) => {
        const formElements = $form.querySelectorAll('.js-cell');
        const formName = $form.getAttribute('data-name');
        const $Form = new Form($form, submitForm);

        formElements.forEach(($el) => {
            $Form.addFormElement($el);
        });

        formsCollection[formName] = $Form;
    });

    popups.forEach(($popup) => {
        const popupName = $popup.getAttribute('data-name');

        popupsCollection[popupName] = new Popup($popup);
    });

    activatedButtons.forEach((button) => button.addEventListener('click', () => popupsCollection.popupApplication.showPopup()));

    $footerYear.textContent = new Date().getFullYear();
    // popupsCollection.popupApplication.showPopup();

    function submitForm() {
        const formData = new FormData(this.$form);
        const formName = this.$form.getAttribute('data-name');

        this.blockingFormButton();

        $Request.sendMail(formData).then((res) => {
            formName === 'popup' && popupsCollection.popupApplication.closePopup();
            popupsCollection.popupSuccessful.showPopup();
            this.unblockingFormButton();
            this.resetForm();
            console.log(111, res);
        });
    }
})();
