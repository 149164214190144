// import Cell from '../Cell';
import { isTextError } from '../../../utils/validation';
import Cell from '../Cell';
import './cellTextarea.scss';

export default class CellTextarea extends Cell {
    constructor($cell, options) {
        super($cell);
        this.$input = this.$cell.querySelector('.js-cell-input');
        this.startValue = this.$input.value;
        this.onChange = options?.onChange;

        this.init();
    }

    init() {
        this.$input.addEventListener('input', this.changesInput.bind(this));
    }

    changesInput() {
        this.removeErrorStatus();
        this.onChange ? this.onChange() : null;
    }

    isInputError(showError = false) {
        const isError = this.isRequired ? isTextError(this.$input) : this.isRequired;

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return isError;
    }

    showField() {
        this.isRequired = this.$initialInput.getAttribute('data-required') !== 'false';
        this.showCell();
    }

    toggleVisibility() {
        this.toggleVisibility();
        if (!this.isVisible) {
            this.isRequired = false;
        } else {
            this.isRequired = this.$initialInput.getAttribute('data-required') !== 'false';
        }
    }

    isInputChanged() {
        return !(this.startValue === this.$initialInput.value);
    }

    resetField() {
        this.$input.value = '';
    }
}
